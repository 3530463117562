body {
	background-color: #ff69b4;
	background-image: url("/public/flyingRose.gif");
}

#main {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

video {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 20px;
}

#text {
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
	font-size: 38px;
	font-weight: bold;
	color: white;
}

#caption {
	color: white;
}

input,
button {
	padding: 10px;
	border-radius: 5px;
	text-align: center;
	margin: 5px;
}

#actions {
	display: flex;
	flex-direction: column;
}
